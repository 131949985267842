import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <section className="flex flex-wrap justify-center pb-20 gap-3">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F650b60f223eaebc583b0e9a9%2Fstock%2FJoshua%20Landscaping%20Snow%20Removal-2025-02-16T05%3A55%3A23.698Z-1.jpg?alt=media&token=dd3008fd-ac1a-49e3-b833-ded0d504dcb1"
            alt="cupon"
            className="w-[250px] lg:w-[350px]"
          />
          <img
              src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F650b60f223eaebc583b0e9a9%2Fstock%2FJoshua%20Landscaping%20Snow%20Removal-2025-02-16T05%3A55%3A23.698Z-2.jpg?alt=media&token=8807f016-57bd-4b0c-a33a-ba37e51feeb9"
              alt="cupon"
              className="w-[250px] lg:w-[350px]"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F650b60f223eaebc583b0e9a9%2Fstock%2FJoshua%20Landscaping%20Snow%20Removal-2025-02-16T05%3A55%3A23.697Z-0.jpg?alt=media&token=b15aecaa-0a8a-4ca0-8240-29b8aff9d340"
            alt="cupon"
            className="w-[250px] lg:w-[350px]"
          />
        </section>
        <div className="flex justify-center gap-5 flex-wrap items-center pb-5">
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rpdata?.gmb?.img} alt="Not found" width={"250px"} />
            </a>
          ) : null}
        </div>
        {rpdata?.dbSocialMedia?.redes.length > 0 ||
        rpdata?.dbSocialMedia?.directorios.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}

        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}
        >
          {rpdata?.dbSocialMedia?.directorios.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
          {rpdata?.dbSocialMedia?.redes.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not Found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
    </div>
  );
}

export default Directories;
